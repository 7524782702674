@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=block');

html {
  box-sizing: border-box;
  font-size: 16px;
  scroll-behavior: smooth;
  font-display: block;
  overscroll-behavior: none;
}

html,
body {
  overflow-x: hidden;
  max-width: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

@-webkit-keyframes bounce {
  to {
    -webkit-transform: scale(1.2);
  }
}
@-moz-keyframes bounce {
  to {
    -moz-transform: scale(1.2);
  }
}
@keyframes bounce {
  to {
    transform: scale(1.2);
  }
}
html {
  background: '#333333';
}
